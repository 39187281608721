<template>
  <div class="base-info">
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
      <Header title="基本信息" />

      <div class="form">
        <a-row :gutter="[12, 12]">
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">姓名：</div>
              <div class="value">
                {{ detail.name }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item 性别">
              <div class="label">性别：</div>
              <div class="value">
                <DataDictFinder dictType="sex" :dictValue="detail.sex" v-if="detail.sex" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">出生日期：</div>
              <div class="value">
                {{ detail.birthday ? detail.birthday : "--" }}
              </div>
            </div>
          </a-col>

          <!-- <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">年龄：</div>
              <div class="value">
                {{ getDateLength("birthday") }}
              </div>
            </div>
          </a-col> -->

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">国籍：</div>
              <div class="value">
                {{ detail.nationality ? detail.nationality : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">籍贯：</div>
              <div class="value">
                {{ detail.nativePlace ? detail.nativePlace : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">民族：</div>
              <div class="value">
                {{ detail.ethnicGroup ? detail.ethnicGroup : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">政治面貌：</div>
              <div class="value">
                <DataDictFinder dictType="politicalOutlook" :dictValue="detail.political" v-if="detail.political" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">入党(团)日期：</div>
              <div class="value">
                {{ detail.politicalDate ? moment(detail.politicalDate).format('YYYY年M月') : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">党员标识：</div>
              <div class="value">
                <DataDictFinder dictType="partyMemberType" :dictValue="detail.partyMember" v-if="detail.partyMember" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <!-- <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">血型：</div>
              <div class="value">
                <DataDictFinder dictType="bloodType" :dictValue="detail.bloodType" v-if="detail.bloodType" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col> -->
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">最高学历：</div>
              <div class="value">
                <DataDictFinder dictType="degree" :dictValue="detail.degree" v-if="detail.degree" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">身高：</div>
              <div class="value">
                {{ detail.height ? detail.height + "cm" : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">体重：</div>
              <div class="value">
                {{ detail.weight ? detail.weight + "kg" : "--" }}
              </div>
            </div>
          </a-col>
          <!-- <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">肤色：</div>
              <div class="value">
                {{ detail.skinColor ? detail.skinColor : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">发色：</div>
              <div class="value">
                {{ detail.hairColor ? detail.hairColor : "--" }}
              </div>
            </div>
          </a-col> -->

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">兴趣爱好：</div>
              <div class="value">
                {{ detail.interests ? detail.interests : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">特长：</div>
              <div class="value">
                {{ detail.expertises ? detail.expertises : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">婚姻状况：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.marrigeStatus" :dictValue="detail.marryType"
                  v-if="detail.marryType" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">子女情况：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.childStatus" :dictValue="detail.childrenType"
                  v-if="detail.childrenType" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">入职日期：</div>
              <div class="value">
                {{ detail.entryDate ? detail.entryDate : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">司龄：</div>
              <div class="value">
                {{ getDateLength("entryDate") }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">参加工作日期：</div>
              <div class="value">
                {{ detail.workStartTime ? detail.workStartTime : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">社会工龄：</div>
              <div class="value">
                {{ getDateLength("workStartTime") }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">主要社会职务：</div>
              <div class="value">
                {{ detail.socialPost ? detail.socialPost : "--" }}
              </div>
            </div>
          </a-col>
          

          
          <!-- <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">荣誉：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.logo" :dictValue="detail.honorFlag" v-if="detail.honorFlag" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">户口类型：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.registerResidenceType" :dictValue="detail.registerResidenceType" v-if="detail.registerResidenceType" />
              </div>
            </div>
          </a-col> -->
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">职称：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.professionalTitle" :dictValue="detail.professionalTitle"
                  v-if="detail.professionalTitle" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">职称时间：</div>
              <div class="value">
                {{ detail.professionalDate ? detail.professionalDate : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item" style="align-items: flex-start;">
              <div class="label">注册执业资格：</div>
              <div style="display: flex;flex-wrap: wrap;" v-if="detail.registeredProfessionalQualifications">
                <div class="value" style="display: flex;" v-for="(item, index) in detail.registeredProfessionalQualifications.split(',')" :key="item">
                  <DataDictFinder dictType="talentMap.certificate.registeredCert" :dictValue="item"
                    v-if="item" />
                  <span v-if="index < detail.registeredProfessionalQualifications.split(',').length - 1">，</span>  
                </div>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">紧急联系人：</div>
              <div class="value">
                {{ detail.emergencyContact ? detail.emergencyContact : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">紧急联系人电话：</div>
              <div class="value">
                {{
                                detail.emergencyContactMobile
                                ? detail.emergencyContactMobile
                                : "--"
                                }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">残疾标识：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.disabilitySign" :dictValue="detail.deformityFlag"
                  v-if="detail.deformityFlag" />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>

      

      <Header title="岗位信息" />
      <div class="form">
        <a-row :gutter="[12, 12]">
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">归属部门：</div>
              <div class="value"></div>
              {{
                            detail.position
                            ? detail.position.belongDept
                            ? renderDept(detail.position.belongDept)
                            : "--"
                            : "--"
                            }}
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">所：</div>
              <div class="value">
                {{ detail.instituteId ? detail.instituteId : "--" }}
              </div>
              <!-- onPlaceChange -->
              <!-- placeList -->
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">组：</div>
              <div class="value">
                {{ detail.groupId ? detail.groupId : "--" }}
              </div>
              <!-- groupList -->
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">岗位：</div>
              <div class="value" v-if="detail.position">
                <a-select class="selectNoBorder" style="width: 250px" size="small" :disabled="true" v-model="detail.position.position">
                  <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">从事专业：</div>
              <div class="value">
                {{ detail.position ? detail.position.professionalize : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">直接上级姓名：</div>
              <div class="value">
                {{ detail.position ? detail.position.directSupervisor : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">岗位序列：</div>
              <div class="value">
                {{ detail.position ? detail.position.postSeries : "--" }}
              </div>
            </div>
          </a-col>
          
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">职级：</div>
              <div class="value">
                {{ detail.position ? detail.position.rank : "--" }}
              </div>
            </div>
          </a-col>
          
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">职等：</div>
              <div class="value">
                {{ detail.position ? detail.position.grade : "--" }}
              </div>
            </div>
          </a-col>

          

          <!-- <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">当前职称开始日期：</div>
              <div class="value">
                {{ detail.position ? detail.position.rankStartTime : "--" }}
              </div>
            </div>
          </a-col> -->
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">任职开始日期：</div>
              <div class="value">
                {{ detail.position ? detail.position.postStartTime : "--" }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">任职结束日期：</div>
              <div class="value">
                {{ detail.position ? detail.position.postEndTime : "--" }}
              </div>
            </div>
          </a-col>

          <!-- <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">合同开始日期：</div>
              <div class="value">
                {{ detail.contractStartDate ? detail.contractStartDate : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">合同结束日期：</div>
              <div class="value">
                {{ detail.contractEndDate ? detail.contractEndDate : "--" }}
              </div>
            </div>
          </a-col> -->
        </a-row>
      </div>
      <Position :detail="detail" />

      <Header title="合同信息" />
      <div class="form">
        <a-row :gutter="[12, 12]">
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">试用期限：</div>
              <div class="value">
                {{ detail.probation ? detail.probation.term + "月" : "--" }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">开始日期：</div>
              <div class="value">
                {{
                                detail.probation
                                ? detail.probation.startTime
                                ? detail.probation.startTime
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">计划结束日期：</div>
              <div class="value">
                {{
                                detail.probation
                                ? detail.probation.endTimeExpected
                                ? detail.probation.endTimeExpected
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">实际结束日期：</div>
              <div class="value">
                {{
                                detail.probation && detail.probation.endTimeActual
                                ? detail.probation.endTimeActual
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">转正日期：</div>
              <div class="value">
                {{
                                detail.probation && detail.probation.qualifiedTime
                                ? detail.probation.qualifiedTime
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <div class="item" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }">
              <div class="label">考核结果：</div>
              <div class="value">
                {{ detail.probation ? detail.probation.result : "--" }}
              </div>
            </div>
          </a-col>
        </a-row>
        <Change :detail="detail" :organizationList="organizationListNew" />
        <Contract :detail="detail"/>
      </div>
      
      <Header title="教育经历" />

      <Education :detail="detail" :graduationWay="graduationWay" :education_channel="education_channel" :degree="degree"
        :academic_degree="academic_degree" />

      <div style="padding-bottom: 16px"></div>
      
      <Header title="证件信息" />
      <Certificates :detail="detail" />
      <div style="padding-bottom: 16px"></div>

      

      <Header title="家庭及主要社会关系信息" />

      <Family :detail="detail" />

      <div style="padding-bottom: 16px"></div>

      

      <Header title="证书信息" />
      <Book :detail="detail" :certificateType="certificateType" />

      <div style="padding-bottom: 16px"></div>

      <Header title="荣誉信息" />

      <Honor :detail="detail" :honorLogo="honorLogo" :honorLevel="honorLevel" :externalHonorLevel="externalHonorLevel" />

      <div style="padding-bottom: 16px"></div>

      <Header title="惩罚信息" />

      <Reward :detail="detail" :rewardPenaltyType="rewardPenaltyType" />

      <div style="padding-bottom: 16px"></div>


      <div style="padding-bottom: 16px"></div>

      <Header title="绩效考核" />

      <Performance :detail="detail" />

      <Header title="培训记录" />
      <Train :detail="detail" />
      <div style="padding-bottom: 16px"></div>

      <div style="padding-bottom: 16px"></div>

      <Header title="人才识别" />

      <div class="form" style="margin-bottom: 12px">
        <a-row :gutter="[12, 12]">
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">第三方评测得分：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.evaluation3rd
                                ? detail.talentIdentify.evaluation3rd
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">文化匹配度：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.culture
                                ? detail.talentIdentify.culture
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">综合能力：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.comprehensive
                                ? detail.talentIdentify.comprehensive
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">管理能力：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.abilityManage
                                ? detail.talentIdentify.abilityManage
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">技术能力：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.abilitySkill
                                ? detail.talentIdentify.abilitySkill
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">销售能力：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.abilitySale
                                ? detail.talentIdentify.abilitySale
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">沟通能力：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.abilityCommunicate
                                ? detail.talentIdentify.abilityCommunicate
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">学习能力：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.abilityLearn
                                ? detail.talentIdentify.abilityLearn
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">人才发展积分：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.scoreDevelop
                                ? detail.talentIdentify.scoreDevelop
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">360评价得分：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.score360
                                ? detail.talentIdentify.score360
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">后备人才：</div>
              <div class="value">
                <DataDictFinder dictType="talentMap.reserveTalentLogo"
                  :dictValue="detail.talentIdentify.reserveTalentFlag" v-if="detail.talentIdentify &&
                                        detail.talentIdentify.reserveTalentFlag
                                        " />
                <span v-else>--</span>
              </div>
            </div>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24">
            <div class="item">
              <div class="label">实习期：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.internship
                                ? detail.talentIdentify.internship
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <div class="item" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }">
              <div class="label">360评价结果：</div>
              <div class="value">
                {{
                                detail.talentIdentify
                                ? detail.talentIdentify.result360
                                ? detail.talentIdentify.result360
                                : "--"
                                : "--"
                                }}
              </div>
            </div>
          </a-col>
        </a-row>
      </div>

      <Personel :detail="detail" />
      <div style="padding-bottom: 16px"></div>



      <Header title="工作项目" />
      <Projects :detail="detail" />
    </a-form>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import Header from "./components/header.vue";
import Certificates from "./components/certificates.vue";
import Family from "./components/family.vue";
import Education from "./components/education.vue";
import Book from "./components/book.vue";
import Honor from "./components/honor.vue";
import Reward from "./components/reward.vue";
import Change from "./components/change.vue";
import Performance from "./components/performance.vue";
import Personel from "./components/personel.vue";
import Train from "./components/train.vue";
import Projects from "./components/projects.vue";
import Contract from "./components/contract.vue";
import Position from "./components/position.vue"

import { mapGetters } from "vuex";

import { fetchDetail } from "@/api/setting/organization";
import moment from "moment"
import request from "@/api/request";
function fetchOrganization() {
    return request({
        url: "/user-service/org/all2",
    });
}
export default {
  mixins: [organization],

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Header,
    Certificates,
    Family,
    Education,
    Book,
    Honor,
    Reward,
    Change,
    Performance,
    Personel,
    Train,
    Projects,
    Contract,
    Position
  },
  data() {
    return {
      placeList: [],
      groupList: [],
      organizationListNew: []
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    positionList() {
      return (
        this.lists.find((item) => item.value === "talentMap_post").children || []
      );
    },
    lists() {
      return this.findDataDict("talentMap");
    },
    // 员工类别
    employeeType() {
      return (
        this.lists.find((item) => item.value === "employeeType").children || []
      );
    },

    // 子女状况
    childStatus() {
      return (
        this.lists.find((item) => item.value === "childStatus").children || []
      );
    },
    // 残疾标识
    disabilitySign() {
      return (
        this.lists.find((item) => item.value === "disabilitySign").children ||
        []
      );
    },

    // 人员类型
    personType() {
      return (
        this.lists.find((item) => item.value === "personType").children || []
      );
    },
    // 关系类型
    relationshipType() {
      return (
        this.lists.find((item) => item.value === "relationshipType").children ||
        []
      );
    },
    // 学历
    degree() {
      return this.findDataDict("degree");
    },
    // 学位
    academic_degree() {
      return this.findDataDict("academic_degree");
    },
    // 学习方式
    education_channel() {
      return this.findDataDict("education_channel");
    },
    // 毕业方式
    graduationWay() {
      return (
        this.lists.find((item) => item.value === "graduationWay").children || []
      );
    },

    // 证书类型
    certificateType() {
      const list = this.lists.find((item) => item.value === "certificateType");
      return list ? list.children : [];
      // return (
      //   .children ||
      //   []
      // );
    },

    // 职称等级
    professionalTitle() {
      return (
        this.lists.find((item) => item.value === "professionalTitle")
          .children || []
      );
    },
    // 荣誉标识
    honorLogo() {
      return (
        this.lists.find((item) => item.value === "honorLogo").children || []
      );
    },
    // 荣誉等级
    honorLevel() {
      return (
        this.lists.find((item) => item.value === "honorLevel").children || []
      );
    },
    externalHonorLevel() {
      return (
        this.lists.find((item) => item.value === "externalHonorLevel")
          .children || []
      );
    },
    // 标识
    logo() {
      return this.lists.find((item) => item.value === "logo").children || [];
    },

    rewardPenaltyType() {
      return (
        this.lists.find((item) => item.value === "rewardPenaltyType")
          .children || []
      );
    },

    // 后备人才标识
    reserveTalentLogo() {
      return (
        this.lists.find((item) => item.value === "reserveTalentLogo")
          .children || []
      );
    },
    // 培训类型
    trainType() {
      return (
        this.lists.find((item) => item.value === "trainType").children || []
      );
    },

    employeeStatus() {
      return this.findDataDict("employeeStatus");
    },
  },

  watch: {
    detail() {
      if (this.detail.position && this.detail.position.belongDept) {
        this.onDeptChange(this.detail.position.belongDept);
      }
    },
  },

  mounted() {
    if (this.detail.position && this.detail.position.belongDept) {
      this.onDeptChange(this.detail.position.belongDept);
    }
    fetchOrganization().then((res)=>{
      this.organizationListNew = res
    })
  },

  methods: {
    moment(value) {
      return moment(value)
    },
    getDateLength(key) {
      const str = this.detail[key];
      if (str) {
        const year = new Date().getFullYear();
        return year - parseInt(str);
      } else {
        return "--";
      }
    },

    renderDept(id) {
      const item = this.organizationList.find((item) => item.id === id);
      return item ? item.uniqueName : "--";
    },

    onDeptChange(value) {
      this.placeList = [];
      this.groupList = [];

      fetchDetail({
        uuid: value,
      }).then((res) => {
        if (res) {
          this.placeList = Array.isArray(res.instituteVoList)
            ? res.instituteVoList
            : [];

          if (this.detail.instituteId) {
            this.onPlaceChange(this.detail.instituteId);
          }
        }
      });
    },

    onPlaceChange(value) {
      const item = this.placeList.find((item) => item.id === value);
      if (item) {
        this.groupList = Array.isArray(item.groupVoList)
          ? item.groupVoList
          : [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.base-info {
  border: 1px solid #f5f5f5;
  padding: 24px 12px 12px;

  .form {
    background-color: #f7f7f7;
    padding: 12px;
    margin-bottom: 28px;
  }

  .item {
    display: flex;
    align-items: center;
    .label {
      width: 8em;
      position: relative;
    }
  }
}
</style>